import { render, staticRenderFns } from "./TheDownloads.vue?vue&type=template&id=64190d14&scoped=true&"
import script from "./TheDownloads.vue?vue&type=script&lang=js&"
export * from "./TheDownloads.vue?vue&type=script&lang=js&"
import style0 from "./TheDownloads.vue?vue&type=style&index=0&id=64190d14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64190d14",
  null
  
)

export default component.exports